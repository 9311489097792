import React, { useEffect, useState } from 'react';
import '../styles/style.css';
import '../styles/LoginStyles.css'
import CreateCampaignScreen from './CreateCampaignScreen';
import FlashOfferScreen from './FlashOfferScreen';
import StatisticsScreen from './StatisticsScreen';
import RestaurantUpdateScreen from './RestaurantUpdateScreen';
import { Button, Modal } from 'react-bootstrap';
import QRCodeConsume from '../popup/QRCodeConsume';
// import { logout } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import ActiveCampaignScreen from './ActiveCampaignScreen';
import { CampaignDTO } from '@/model/dto/CampaignDTO';
import AboutUsScreen from './AboutUsScreen';
import TermsAndConditionsScreen from './TermsAndConditionsScreen';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';
import moment from 'moment';
import {
    routeAboutUs,
    routeHome,
    routeHomeFlashDeals,
    routeHomeMyRestaurant,
    routeHomeStatistics,
    routeLogin,
    routePrivacyPolicy,
    routeSuperAdmin,
    routeTermsAndConditions,
    RoutingHelper
} from '../../constants/routes';
import HomeLandingScreen from './HomeLandingScreen';
import { Repository } from '../../data/repository/repository';
import { TimeHelper } from "../../helpers/TimeHelper";
import CreateCampaignContainerScreen from './CreateCampaignContainerScreen';
import ActiveWeeklyPlanScreen from './ActiveWeeklyPlanScreen';
import EditCampaignPopup from '../popup/EditCampaignPopup';
import MissingDayDiscountOpeningScreen from './MissingDayDiscountOpeningScreen';
import OpenMissingDayPopup from '../popup/OpenMissingDayPopup';
import FutureCampaignScreen from './FutureCampaignScreen';
import FlashOfferComingSoonScreen from './FlashOfferComingSoonScreen';



export default function CampaignContainerScreen() {




    const [currentComponent, setCurrentComponent] = useState(<CreateCampaignScreen />);
    const [activeCampaign, setActiveCampaign] = useState(false)
    const [activeAutomation, setActiveAutomation] = useState(false)
    const [activeButton, setActiveButton] = useState('onlineOl');
    const [showQRModal, setShowQRModal] = useState<boolean>(false);
    const [showLogoutPopup, setShowLogoutPopup] = useState(false);
    const logo = require('../images/yerimLogo.png');
    const logOut = require('../images/icons/Logout.png')
    const [campaignId, setCampaignId] = useState('')
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();


    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);


    const hideLink = process.env.REACT_APP_HIDE_LINK === 'true';

    const qr = require('../images/icons/Qr-code.png')
    const qrWhite = require('../images/icons/Qr-code-white.png')



    /*
    const fetchData = async (companyId: String) => {
        try {
            if (companyId) {
                const data = await Repository.getCompany(companyId);
                setCompanyName(data?.name);
            }
        } catch (error) {
            console.log(error);
        }
    };
    */


    useEffect(() => {

        if (!Repository.hasValidSession()) {
            RoutingHelper.navigateByUrl(routeLogin)
        }
        else {
            const company = Repository.getMyCompany()
            if (company?.userRole == 3) {
                if (window.location.pathname.includes(routeHomeFlashDeals)) {
                    navigateToFlashDeal()
                }
                else if (window.location.pathname.includes(routeHomeStatistics)) {
                    navitgateToStatistics()
                }
                else if (window.location.pathname.includes(routeHomeMyRestaurant)) {
                    navigateToMyRestaurant()

                }
                else if (window.location.href.includes(routeAboutUs)) {

                }
                else if (window.location.href.includes(routeTermsAndConditions)) {

                }
                else if (window.location.href.includes(routePrivacyPolicy)) {

                }
                else {
                    navigateToHome()

                }
            }
            else if (company?.userRole == 0) {
                RoutingHelper.navigateByUrl(routeSuperAdmin)
            }
            else {
                RoutingHelper.navigateByUrl(routeLogin)
            }
        }


    }, [activeCampaign, window.location.pathname]); // removed  company on effect dependency ? is this ok?


    const setHomeAsActiveCampaigns = () => {
        setCurrentComponent(<ActiveCampaignScreen />);
        setActiveCampaign(true)
    }



    const setHomeAsNoCampaign = () => {
        setCurrentComponent(<CreateCampaignContainerScreen />);
        setActiveCampaign(false)
    }

    const setHomeAsActiveAutomation = () => {
        setCurrentComponent(<ActiveWeeklyPlanScreen />)
        setActiveAutomation(true)
    }

    const setHomeAsMissingPlanDay = () => {
        setCurrentComponent(<MissingDayDiscountOpeningScreen />)
    }

    const setHomeAsFutureCampaign = () => {
        setCurrentComponent(<FutureCampaignScreen />)
    }

    const setHomeAsNoAutomation = () => {
        setCurrentComponent(<CreateCampaignContainerScreen />);
        setActiveAutomation(false)
    }

    const fetchCampaignsData = async () => {
        try {
            const campaigns = await Repository.fetchMyCampaigns();
            const hasActiveAutomation = await Repository.getActiveAutomation(Repository.getMyCompany()?.id ?? '');
            if (Array.isArray(campaigns)) {
                const activeCampaigns = campaigns.filter((obj: CampaignDTO) => {
                    const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss")
                    const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss")
                    return obj.isActive;

                    //return finishDate > new Date() && (obj.isActive || startDate > new Date());
                });

                const promotionTypeZeroCampaigns = activeCampaigns.filter((campaign: any) => campaign.promotionType === 0);
                const futureCampaigns = campaigns.filter((obj: CampaignDTO) => {
                    const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss");
                    const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss");
                    return startDate.isAfter(TimeHelper.now()) && obj.promotionType === 0 && finishDate.isAfter(TimeHelper.now());
                });
                const currentDay = new Date().getDay();

                if (hasActiveAutomation) {
                    const activeDays = hasActiveAutomation.activeDays;
                    const startTime = moment(hasActiveAutomation.startTime, "HH:mm:ss");

                    if (activeCampaigns.length < 1) {
                        setTimeout(setHomeAsMissingPlanDay, 250);
                    } else if (activeDays.includes(currentDay)) {
                        setTimeout(setHomeAsActiveAutomation, 250);
                    } else {
                        setTimeout(setHomeAsMissingPlanDay, 250);
                    }
                } else if (promotionTypeZeroCampaigns.length > 0 && hasActiveAutomation === null) {
                    setTimeout(setHomeAsActiveCampaigns, 250);
                } else if (futureCampaigns.length > 0) {
                    setTimeout(setHomeAsFutureCampaign, 250)
                }
                else {
                    setTimeout(setHomeAsNoCampaign, 250);
                }
            }
        } catch (error) {
            console.error(error);
        };
    }



    const handleShowQRModal = () => {
        setShowQRModal(true);
    };

    const handleCloseQRModal = () => {
        setShowQRModal(false);
    };


    const handleShowLogoutPopup = () => {
        setShowLogoutPopup(true);

    };


    const handleComponentChange = (component: any, buttonName: string) => {
        setCurrentComponent(component);
        setActiveButton(buttonName);
    };

    const handleLogout = async () => {
        await Repository.logOut()
        RoutingHelper.navigateByUrl(routeLogin)
    };

    const navigateToHome = () => {
        setActiveButton(routeHome)
        navigate(routeHome)
        setCurrentComponent(<HomeLandingScreen />)
        //setCurrentComponent(<CreateCampaignScreen />)
        fetchCampaignsData();

    }

    const navigateToFlashDeal = () => {
        setActiveButton(routeHomeFlashDeals)
        navigate(routeHomeFlashDeals)
        console.log('hide link kontrol:', hideLink);
        // if (hideLink){
        //     setCurrentComponent(<FlashOfferComingSoonScreen/>)
        // }else {
        //     setCurrentComponent(<FlashOfferScreen />)
        // }
        setCurrentComponent(<FlashOfferComingSoonScreen />)
    }

    const navitgateToStatistics = () => {
        setActiveButton(routeHomeStatistics)
        navigate(routeHomeStatistics)
        setCurrentComponent(<StatisticsScreen />)
    }

    const navigateToMyRestaurant = () => {
        setActiveButton(routeHomeMyRestaurant)
        navigate(routeHomeMyRestaurant)
        setCurrentComponent(<RestaurantUpdateScreen />)
    }

    const navigateToAboutUs = () => {
        setCurrentComponent(<AboutUsScreen />)
        navigate(routeAboutUs)

    }
    const navigateToTermsAndConditions = () => {
        setCurrentComponent(<TermsAndConditionsScreen />)
        navigate(routeTermsAndConditions)
    }

    const navigateToPrivacyPolicy = () => {
        setCurrentComponent(<PrivacyPolicyScreen />)
        navigate(routePrivacyPolicy)
    }



    const logoutConfirmationModal = (
        <div >
            <Modal className='logout-popup' show={showLogoutPopup} onHide={() => setShowLogoutPopup(false)}>
                <Modal.Header>
                    <Modal.Title>Çıkış Yapıyorsunuz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Tekrar panele erişebilmek için şifre ile giriş yapmanız gerekecektir. Devam etmek istiyor musunuz?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button id='logout-exit-button' variant="secondary" onClick={() => setShowLogoutPopup(false)}>
                        Vazgeç
                    </Button>
                    <Button id='logout-exit-button' variant="primary" onClick={handleLogout}>
                        Evet
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

    const navbarItems = [
        { name: 'Online Ol', onClick: navigateToHome, active: activeButton === routeHome },
        { name: 'İstatistik', onClick: navitgateToStatistics, active: activeButton === routeHomeStatistics },
        { name: 'Restoranım', onClick: navigateToMyRestaurant, active: activeButton === routeHomeMyRestaurant },
        { name: 'Flash Teklif Aç', onClick: navigateToFlashDeal, active: activeButton === routeHomeFlashDeals },
    ];

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col">
                    <div className="horizontal-container">
                        <div className="horizontal-div upper-div">
                            <div className="navbar">
                                <div className="navbar-logo">
                                    <img src={logo} alt='logo' />
                                </div>

                                <div className='navbar-right-part'>
                                    <button className='companyButton qrButtonMobile' style={{ width: '100px', color: 'white', height: '52px',borderRadius:'8px' }} onClick={handleShowQRModal}><img style={{ width: '20px', marginRight: '5px' }} src={qrWhite} alt='qr-code' /> QR</button>

                                    <div className="navbar-toggle" onClick={toggleMenu}>

                                        <span className={`bar ${isMenuOpen ? 'change' : ''}`}></span>
                                        <span className={`bar ${isMenuOpen ? 'change' : ''}`}></span>
                                        <span className={`bar ${isMenuOpen ? 'change' : ''}`}></span>
                                    </div>

                                    <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
                                        {navbarItems.map((item, index) => (
                                            <li key={index}>
                                                <button
                                                    className={`navbar-component-buttons ${item.active ? 'active' : ''}`}
                                                    onClick={() => {
                                                        item.onClick();
                                                        setIsMenuOpen(false);
                                                    }}
                                                >
                                                    {item.name}
                                                </button>
                                            </li>
                                        ))}

                                        <li className="logout-button-mobile">
                                            {logoutConfirmationModal}
                                            <button
                                                style={{
                                                    color: '#F15F50',
                                                    borderRadius: '5px',
                                                    padding: '15px',
                                                    backgroundColor: '#50536789',
                                                    fontSize: '16px',
                                                }}
                                                className="companyButton navbar-component-buttons"
                                                onClick={() => {
                                                    handleShowLogoutPopup();
                                                    setIsMenuOpen(false);
                                                }}
                                            >
                                                <img src={logOut} alt="logout" /> Çıkış
                                            </button>
                                        </li>

                                    </ul>

                                    <div className={`navbar-right`}>
                                        {logoutConfirmationModal}
                                        <button
                                            style={{
                                                color: '#F15F50',
                                                borderRadius: '5px',
                                                padding: '15px',
                                                backgroundColor: '#50536789',
                                                fontSize: '16px',
                                            }}
                                            className="companyButton navbar-component-buttons"
                                            onClick={() => {
                                                handleShowLogoutPopup();
                                                setIsMenuOpen(false);
                                            }}
                                        >
                                            <img src={logOut} alt="logout" /> Çıkış
                                        </button>
                                    </div>
                                </div>
                            </div>




                            <div className='companyTitle ' >
                                <div style={{ display: 'block', marginLeft: '16px' }}>
                                    <h2>Merhaba, {Repository.getMyCompany()?.name} 😎</h2>
                                    <p style={{ fontWeight: '100' }}>Yerim'e Tekrar Hoşgeldin.</p>
                                </div>

                                <button className='companyButton qrButton' style={{ width: '175px', color: 'white', height: '55px' }} onClick={handleShowQRModal}><img style={{ width: '20px', marginRight: '5px' }} src={qr} alt='qr-code' /> QR Kodu Okut</button>
                            </div>
                        </div>

                        <div>
                            <Modal
                                show={showQRModal}
                                onHide={handleCloseQRModal}
                                contentLabel="QR Code Modal"
                                overlayClassName="custom-overlay"
                            >
                                <QRCodeConsume onClose={handleCloseQRModal} campaignId={campaignId} />
                            </Modal>
                        </div>





                    </div>

                    <div className="overlay-div">
                        {currentComponent}

                        <div className='bottomLinks'>
                            <button onClick={() => navigateToAboutUs()}>Hakkımızda</button>
                            <button onClick={() => navigateToTermsAndConditions()}>Şartlar ve Koşullar</button>
                            <button onClick={() => navigateToPrivacyPolicy()}>Gizlilik Politikası</button>
                        </div>
                    </div>



                </div>
            </div>


        </div>
    );
}