import React from 'react'
import '../styles/cominSoonStyles.css'

export default function StatisticsScreen() {
  const bg = require('../images/Sol_color.png')
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-sm-2 backgroundDiv'>
          <img style={{ height: '100%' }} src={bg} />

        </div>
        <div className='col-sm-10 contentPart'>
          <div className='comingSoon'>
            <h1>⌛️</h1>
            <h4 className='comingSoonTitle'>Çok Yakında</h4>
            <p>Yerim’i kullandığınızda müşterileriniz ile ilgili çeşitli istatistikleri bu alanda görebileceksiniz. İstatistikler aktif olduğunda sizlere mail ve bildirim aracılığı ile haber verilecektir.</p>
            <p>Teşekkürler.</p>
            <p className='comingSoonQuestion'>Ne Zaman İstatistiklerimi Göreceğim?</p>
            <p>2024</p>
          </div>
        </div>
      </div>
    </div>
  )
}
