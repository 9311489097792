import React from 'react'
import Lottie from 'react-lottie-player';

export default function HomeLandingScreen() {

    const leftColumnBackground = require('../images/icons/Sol_color.png');
    const logo = require('../images/yerimLogo.png');
    const animationData = require('../lottie/yerim_radar.json')

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-sm-2'>
                    <div className='backgroundDiv'>
                        <img src={leftColumnBackground} alt="background"/>
                    </div>

                </div>
                <div className='col-sm-10 contentPart'>
                    <div>
                        <Lottie
                            loop={true}
                            animationData={animationData}
                            play={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
