import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap';
import CreateCampaignScreen from './CreateCampaignScreen';
import CreateWeeklyPlanningScreen from './CreateWeeklyPlanningScreen';
import '../styles/ActiveWeeklyStyle.css';

export default function CreateCampaignContainerScreen() {
    const leftColumnBackground = require('../images/weeklyplan.png');
    const [activeTab, setActiveTab] = useState('GünlükPlan');

    const getBackgroundStyle = () => {
        switch (activeTab) {
            case 'GünlükPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '120vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            case 'HaftalıkPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '200vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    };

    return (

        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2 backgroundDiv">
                    <div className='backgroundDiv'>
                        <img  style={getBackgroundStyle()} src={leftColumnBackground} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div className='contentDiv'>
                        <div className='titleOnline'>
                            <h2>
                                Hemen Online Ol!
                            </h2>
                            <hr style={{ width: '100%' }} />
                        </div>

                        <div>
                            <Tabs
                                defaultActiveKey="GünlükPlan"
                                id="justify-tab-example"
                                className="mb-3 custom-tabs"
                                justify
                                onSelect={(tab) => {
                                    if (tab) {
                                        setActiveTab(tab);
                                    }
                                }}
                            >
                                <Tab tabClassName="custom-tab" eventKey="GünlükPlan" title="Günlük Plan">
                                    <CreateCampaignScreen />
                                </Tab>
                                <Tab tabClassName="custom-tab" eventKey="HaftalıkPlan" title="Haftalık Plan">
                                    <CreateWeeklyPlanningScreen />
                                </Tab>
                            </Tabs>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
