

export class ReflectionHelper {


    static deepCopy<T>(obj: T): DeepClone<T> {
        return JSON.parse(JSON.stringify(obj));
    }

}


export type DeepClone<T> = T extends object ? {
    [K in keyof T]: DeepClone<T[K]> } : T;