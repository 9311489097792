import React, { ReactNode, useEffect, useState } from 'react';
import '../styles/style.css';
import { BsFillCaretDownFill } from 'react-icons/bs';
import moment from 'moment';
import 'moment/locale/tr';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


import { routeLogin, RoutingHelper } from '../../constants/routes';
import { Repository } from '../../data/repository/repository';
import { TimeHelper } from "../../helpers/TimeHelper";
import { Constants } from "../../constants/constants";
import { TimeOptionType } from '../../model/enums';
import CreateWeeklyPlanningScreen from './CreateWeeklyPlanningScreen';
import CreateCampaignContainerScreen from './CreateCampaignContainerScreen';


export default function CreateCampaignScreen() {

    // region image files
    const leftColumnBackground = require('../images/icons/Sol_color.png');
    const arrow = require('../images/icons/Text--new-line.png');
    // endregion

    const [documentUrls, setDocumentUrls] = useState({ agreementDocumentUrl: '', privacyDocumentUrl: '', termsDocumentUrl: '' });

    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [expandedTimeOptionsOn, setExpandedTimeOptionsOn] = useState<boolean>(false);

    const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);

    const [selectedStartTime, setselectedStartTime] = useState<moment.Moment | null>(null);
    const [selectedFinishTime, setSelectedFinishTime] = useState<moment.Moment | null>(TimeHelper.getNearesttHalfHour());

    const [startTimeOptions, setStartTimeOptions] = useState<ReactNode[]>(createTimeOptionButtons(TimeOptionType.start));
    const [endTimeOptions, setEndTimeOptions] = useState<ReactNode[]>(createTimeOptionButtons(TimeOptionType.end));

    const [activeTimeOptionType, setActiveTimeOptionType] = useState<number>(TimeOptionType.none);


    const navigate = useNavigate();
    useEffect(() => {
        if (!Repository.hasValidSession()) {
            RoutingHelper.navigateByUrl(routeLogin)
        }
    }, [navigate]);

    useEffect(() => {
        getDocuments()
    }, []);

    useEffect(() => {
        setErrorMessage(null);
    }, [selectedStartTime, selectedFinishTime, selectedDiscountValue]);

    useEffect(() => {
        setStartTimeOptions(createTimeOptionButtons(TimeOptionType.start));
        setEndTimeOptions(createTimeOptionButtons(TimeOptionType.end));
    }, [expandedTimeOptionsOn]);


    useEffect(() => {

        setEndTimeOptions(createTimeOptionButtons(TimeOptionType.end));

        if (selectedFinishTime == null ||
            (selectedStartTime != null && (selectedStartTime.diff(selectedFinishTime, 'minute') ?? 0) >= 0)) {
            const nearestTime = TimeHelper.getNearesttHalfHour((selectedStartTime ?? TimeHelper.now()).clone().add(30, 'minute'))
            setSelectedFinishTime(nearestTime)
        }

        setActiveTimeOptionType(TimeOptionType.none);
    }, [selectedStartTime]);

    useEffect(() => {
        setActiveTimeOptionType(TimeOptionType.none);
    }, [selectedFinishTime]);

    async function getDocuments() {
        await Repository.getAppDocuments()
        setDocumentUrls({
            agreementDocumentUrl: Repository.getAppDocumentUrl('A2'),
            privacyDocumentUrl: Repository.getAppDocumentUrl('P101P'),
            termsDocumentUrl: Repository.getAppDocumentUrl('A1')
        });
    }
    const handleStartTimeButtonClick = () => {
        if (activeTimeOptionType == TimeOptionType.start) {
            setActiveTimeOptionType(TimeOptionType.none);
        }
        else {
            setActiveTimeOptionType(TimeOptionType.start);
        }
    };
    const handleEndTimeButtonClick = () => {
        if (activeTimeOptionType == TimeOptionType.end) {
            setActiveTimeOptionType(TimeOptionType.none);
        }
        else {
            setActiveTimeOptionType(TimeOptionType.end);
        }
    };



    const handleStartTimeSelect = (time: moment.Moment) => {
        setselectedStartTime(time)
    }

    const handleEndTimeSelect = (time: moment.Moment) => {
        setSelectedFinishTime(time)
    }

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxChecked(e.target.checked);
    };

    const handleDiscountValueClick = (value: number) => {
        setSelectedDiscountValue(value);
    };

    function createTimeOptionButtons(optionType: TimeOptionType): ReactNode[] {

        const buttons: ReactNode[] = [];
        const currentTime = TimeHelper.now().startOf('hour').add(30, 'minutes');
        const remainingTimeToDayEnd = TimeHelper.getRemainingMinutesUntilDayEnd()
        const endTime = TimeHelper.now().endOf('day')
            .add(optionType == TimeOptionType.end ? 30 : 0, 'minute') // so that end time options include 00:00
            .add(remainingTimeToDayEnd >= Constants.CAMPAIGN_TIME_OPTIONS_NIGHTTIME_THRESHOLD ? 0 : Constants.CAMPAIGN_TIME_NIGHTTIME_EXPAND, 'minute');

        let intervals = endTime.diff(currentTime, 'minutes') / 30;

        if (optionType == TimeOptionType.end) {
            intervals = endTime.diff(selectedStartTime ?? currentTime, 'minutes') / 30;
        }

        if (!expandedTimeOptionsOn && intervals > Constants.DEFAULT_CAMPAIGN_TIME_OPTIONS_COUNT) {
            intervals = Constants.DEFAULT_CAMPAIGN_TIME_OPTIONS_COUNT;
        }

        if (optionType == TimeOptionType.start) {
            buttons.push(
                <button
                    key="now"
                    onClick={() => handleStartTimeSelect(TimeHelper.now())}
                    className={'timeButtons'}>
                    Şimdi
                </button>
            );
        }

        for (let i = 0; i <= intervals; i++) {

            let time = currentTime.clone().add(i * 30, 'minutes');
            if (optionType == TimeOptionType.end && selectedStartTime != null) {
                time = TimeHelper.getNextNearestHalfHour(selectedStartTime.clone()).add(i * 30, 'minutes');
                //selectedStartTime.clone().add(i * 30, 'minutes');
            }
            buttons.push(
                <button
                    key={i}
                    onClick={() => {
                        if (optionType == TimeOptionType.start) {
                            handleStartTimeSelect(time);
                        }
                        else {
                            handleEndTimeSelect(time);
                        }
                    }
                    }
                    className={'timeButtons'}>
                    {time.format('HH:mm')}
                </button>
            );
        }

        if (!expandedTimeOptionsOn) {
            buttons.push(
                <button
                    key="show-more"
                    onClick={() => setExpandedTimeOptionsOn(true)}
                    className='show-more-button'
                    style={{ width: '250px' }}>
                    Daha Fazla Göster
                </button>
            );
        }

        return buttons
    }
    const handleBecomeOnline = async () => {
        if (!checkboxChecked) {
            setErrorMessage('Lütfen boş alanları doldurun.')
            return;
        }

        if (selectedDiscountValue === null || selectedDiscountValue < 0) {
            toast.error("Lütfen indirim oranı seçiniz")
            return;
        }

        if (selectedStartTime !== null && selectedStartTime >= selectedFinishTime!) {
            toast.error("Başlama tarihi bitiş tarihinden büyük veya eşit olamaz")
            return;
        }

        setErrorMessage(null);

        const campaignData = {
            companyId: Repository.getMyCompany()?.id ?? '',
            promotionType: 0,
            startDate: selectedStartTime ? selectedStartTime.format('YYYY-MM-DDTHH:mm:ss') : TimeHelper.now().format('YYYY-MM-DDTHH:mm:ss'),
            finishDate: selectedFinishTime ? selectedFinishTime.format('YYYY-MM-DDTHH:mm:ss') : '',
            discountRate: selectedDiscountValue || 0,
            agreementUrl: "",
            isActive: true
        };

        try {
            const result = await Repository.createCampaign(campaignData);
            if (result) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };



    return (
        <>
      
          <div className={activeTimeOptionType !== TimeOptionType.none ? 'disabled' : ''}>
            <div className="row upperContent left-content" style={{ marginLeft: '5px' }}>
              <div className="col-6">
                <p id='select-daily-time-p' style={{ color: '#1D1E25' }} className="upperContent-p">
                  Ne zaman online olmak istiyorsun?
                </p>
                <button
                id='select-daily-campaign-start-time'
                  onClick={() => handleStartTimeButtonClick()}
                >
                  {selectedStartTime ? selectedStartTime.format('HH:mm') : 'Şimdi'}
                  <BsFillCaretDownFill style={{ width: '12px' }} />
                </button>
              </div>
              <div className="col-6">
                <p style={{ color: '#1D1E25' }} className="upperContent-p">
                  Ne zaman offline olmak istiyorsun?
                </p>
                <button
                id='select-daily-campaign-end-time'
                  style={{ border: '2px dashed #cd3636' }}
                  onClick={() => handleEndTimeButtonClick()}
                >
                  {selectedFinishTime?.format('HH:mm')}
                  <BsFillCaretDownFill style={{ width: '12px' }} />
                </button>
              </div>
      
              {activeTimeOptionType === TimeOptionType.start && (
                <div className="modal-content">
                  <div id='timeButtons' className="timeButtons">{startTimeOptions}</div>
                </div>
              )}
              {activeTimeOptionType === TimeOptionType.end && (
                <div className="modal-content">
                  <div id='timeButtons' className="timeButtons">{endTimeOptions}</div>
                </div>
              )}
      
              <hr style={{ width: '95%' }} />
              <div>
                <p className="upperContent-p">Ne kadarlık bir indirim tanımlayacaksın?</p>
                <div className="saleButtons">
                  {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
                    <button
                      key={value}
                      onClick={() => handleDiscountValueClick(value)}
                      className={selectedDiscountValue === value ? 'selected-button' : 'normal-button'}
                    >
                      {`${value}%`} {selectedDiscountValue === value && '🤩'}
                    </button>
                  ))}
                </div>
              </div>
              <hr style={{ width: '95%' }} />
              <div className="contract">
                <Checkbox sx={{
                        '&.Mui-checked': {
                            color: '#F15F50'
                        },
                        '&.Mui-checked:hover': {
                            backgroundColor: 'rgba(255, 165, 0, 0.08)',
                        }
                    }}  style={{ transform: 'scale(1.5)', marginRight: '10px' }} onChange={handleCheckboxChange} checked={checkboxChecked} />
                <p>
                  Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz
                  olarak sunacağımı kabul ederim.
                </p>
              </div>
              {errorMessage !== null && <p style={{ color: 'red' }}>{errorMessage}</p>}
      
              <div id='companyBecomeOnlineButton' className="companyBecomeOnlineButton">
                <button style={{ width: '300px' }} onClick={handleBecomeOnline}>
                  <img src={arrow} style={{marginRight:'10px'}} alt="arrow" />
                  Online Ol
                </button>
              </div>
            </div>
          </div>
        </>
      );
      
}


