import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './components/screens/Login';
import RegisterScreen from './components/screens/RegisterScreen';
import CampaignContainerScreen from './components/screens/CampaignContainerScreen';
import SuperAdminHomeScreen from './components/screens/SuperAdminHomeScreen';
import ForgotPasswordScreen from './components/screens/ForgotPasswordScreen';
import DeleteAccountScreen from './components/screens/DeleteAccountScreen';
import { routeAboutUs, routeDeleteAccount, routeForgotPassword, routeHome, routeLogin, routeRegister, routeSuperAdmin } from './constants/routes';
import {Repository} from "./data/repository/repository";
import {TimeHelper} from "./helpers/TimeHelper";
import Modal from 'react-modal';



const App: React.FC = () => {

  Modal.setAppElement('#root');


    useEffect(() => {
        Repository.fetchRepoVersions().then(r =>
            console.info('repo versions fetched')
        )
        TimeHelper.fetchAndCalculateTimeDifference().then(r =>
            console.info('time-sync completed')
        )




    }, []);



    return (
      <Router>
        <ToastContainer />
        <Routes>
          <Route path={routeLogin} element={<Login />} />
          <Route path={routeRegister} element={<RegisterScreen />} />
          <Route path="/home" element={<CampaignContainerScreen />} />
          <Route path={routeHome} element={<CampaignContainerScreen />} />
          <Route path={routeSuperAdmin} element={<SuperAdminHomeScreen />} />
          {/* <Route path="/live-right-now" element={<ActiveCampaignScreen />} /> */}
          <Route path={routeForgotPassword} element={<ForgotPasswordScreen/>}/>
          <Route path={routeDeleteAccount} element={<DeleteAccountScreen/>}/>


        </Routes>
      </Router>
  );
};

export default App;
