import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { Repository } from '../../data/repository/repository';
import { TimeHelper } from '../../helpers/TimeHelper';
import { Checkbox } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import React, { ReactNode, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';


interface Props {
    onClose: () => void;
    campaignId: string | undefined;
    startTime: string | undefined;
    finishTime: string | undefined;
    discountRate: number | undefined
}

export default function EditCampaignPopup({ onClose, campaignId, startTime, finishTime, discountRate }: Props) {
    const leftColumnBackground = require('../images/weeklyplan.png');
    const [activeTab, setActiveTab] = useState('GünlükPlan');
    const lock = require('../images/icons/Locked.png');
    const arrow = require('../images/icons/Text--new-line.png');
    const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);
    const [closingTime, setClosingTime] = useState<string | undefined>('');
    const currentMoment = TimeHelper.now();
    const formattedDate = currentMoment.format("D MMMM dddd YYYY");

    const getBackgroundStyle = () => {
        switch (activeTab) {
            case 'GünlükPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '120vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            case 'HaftalıkPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '200vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    };

    const handleDiscountValueClick = (value: number) => {
        setSelectedDiscountValue(value);
    };

    const handleSubmit = async () => {
        if (!campaignId) {
            console.error('Kampanya ID bulunamadı.');
            return;
        }
        if (selectedDiscountValue === null || selectedDiscountValue < 0) {
            toast.error("Lütfen indirim oranı seçiniz")
            return;
        }

        if (!closingTime) {
            toast.error("Lütfen kapanış saati seçiniz")
            return;
        }
        const data: any = {};

        if (startTime) {
            data.startTime = startTime;
        }

        if (closingTime) {
            const parsedClosingTime = moment(closingTime, 'HH:mm', true);
            if (!parsedClosingTime.isValid()) {
                toast.error("Geçersiz bir kapanış saati girdiniz.");
                return;
            }
            data.finishTime = parsedClosingTime.format('HH:mm:ss');
        }

        if (selectedDiscountValue !== null) {
            data.discountRate = selectedDiscountValue;
        } else if (discountRate !== undefined) {
            data.discountRate = discountRate;
        }

        try {
            const success = await Repository.updateAutomation(data, campaignId);
            if (success) {
                console.log('Kampanya başarıyla güncellendi');
                onClose();
                window.location.reload();
            } else {
                console.error('Kampanya güncellenirken bir hata oluştu.');
            }
        } catch (error) {
            console.error('İstek sırasında bir hata oluştu:', error);
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img style={getBackgroundStyle()} src={leftColumnBackground} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div>
                        <div className='titleLiveRightNov' style={{ padding: '40px', marginTop: '-20px', marginBottom: '-90px' }}>
                            <div className=' titleOnline stopSession'>
                                <h2 style={{ marginTop: '20px', marginLeft: '-20px' }}>
                                    İndirimi Düzenle
                                </h2>
                                <button id='close-button' onClick={onClose} style={{ marginTop: '20px' }}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                            </div>
                            <p className='date-title' style={{ marginLeft: '-20px' }}>Bugün {formattedDate}</p>

                            <hr className='edit-campaign-line' style={{ width: '100%', marginLeft: '-20px', marginTop: '40px', marginBottom: '40px' }} />
                        </div>
                        <div className='row weekly-plan-content' style={{ padding: '40px' }}>
                            <div className='edit-campaign-times-container' style={{ display: 'flex' }}>
                                <div style={{ marginRight: '60px' }}>
                                    <p className='weekly-active-plan-title'>Ne zaman açılsın?</p>
                                    <button id='edit-campaing-time-disabled' style={{ border: '2px dashed #1D1E2533', width: '250px', fontSize: '40px', lineHeight: '50px' }} ><img className='icon' src={lock} style={{ width: '35px', marginBottom: '10px', marginRight: '5px' }} /> {startTime?.substring(0, 5)}</button>
                                </div>
                                <div
                                    className='weekly-middle-div'
                                    style={{
                                        height: '120px',
                                        width: '1px',
                                        backgroundColor: '#1D1E2533',
                                        marginRight: '60px'
                                    }}
                                ></div>

                                <div>
                                    <p className='weekly-active-plan-title'>
                                        Ne zaman kapansın?
                                    </p>
                                    <input
                                        placeholder={`${closingTime}`}
                                        type="time"
                                        value={closingTime}
                                        onChange={(e) => setClosingTime(e.target.value)}
                                        style={{
                                            color: "black",
                                            backgroundColor: "white",
                                            border: '1px dashed #1D1E2533',
                                            borderRadius: '12px',
                                            padding: '16px',
                                            height: '82px',
                                            width: '160px',
                                            appearance: 'textfield',
                                            WebkitAppearance: 'none',
                                            MozAppearance: 'textfield',
                                        }}
                                    />
                                </div>
                            </div>

                            <hr style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />

                            <div>
                                <p className='weekly-active-plan-title'>İndirim Oranında Değişiklik</p>
                                <div className='discountButtons'>
                                    {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
                                        <button style={{ marginBottom: '15px' }}
                                            key={value}
                                            onClick={() => handleDiscountValueClick(value)}
                                            className={selectedDiscountValue === value ? 'selected-button' : 'normal-button'}>
                                            {`${value}%`} {selectedDiscountValue === value && '🤩'}
                                        </button>
                                    ))}
                                </div>
                            </div>

                            <div id='companyBecomeOnlineButton'>
                                <button style={{ marginTop: '20px', backgroundColor: '#F15F50', fontSize: '16px', paddingTop: '16px', paddingLeft: '22px', paddingBottom: '16px', paddingRight: '22px',color:'#FFFF',border:'none' }} onClick={handleSubmit}><img src={arrow} alt="arrow" /> Değişiklikleri Kaydet</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
