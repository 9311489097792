

export class Sleep {


    public static async for(ms: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }




}