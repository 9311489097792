import {string} from "yup";


export class StorageHelper {


    /*
    // this doesnt work and wont fork for a while : https://stackoverflow.com/questions/27945515/is-it-possible-to-get-name-of-the-interface-as-a-string
    public static setObject(value: any | null, key: string | null = null) {
        StorageHelper.setObjectWithKey(value, key ?? typeof value)
    }*/

    public static setObject(key:string,value: any | null) {
        if(!value) {
            localStorage.removeItem(key)
        }
        else {
            localStorage.setItem(key, JSON.stringify(value))
        }
    }

    public static getObject<S>(key:string): any | null {
        const json = localStorage.getItem(key)
        if(json == null) {
            return null
        }
        return JSON.parse(json) as S
    }

    public static setString(key: string, value:string | null) {
        if(!value) {
            localStorage.removeItem(key)
            return
        }

        localStorage.setItem(key, value)
    }

    public static getString(key:string): string | null {
        return localStorage.getItem(key)
    }

}