import { Checkbox } from '@mui/material';
import { TimeHelper } from '../../helpers/TimeHelper';
import React, { useRef, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { Repository } from '../../data/repository/repository';
import InputMask from 'react-input-mask';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';


interface Props {
    onClose: () => void;
    startTime: string;
    finishTime: string
    startDate: string;
    finishDate: string;
    automationId: string
}


export default function OpenMissingDayPopup({ onClose, startTime, finishTime, startDate, finishDate, automationId }: Props) {
    const leftColumnBackground = require('../images/weeklyplan.png');
    const [activeTab, setActiveTab] = useState('GünlükPlan');
    const arrow = require('../images/icons/Text--new-line.png');

    const [openingTime, setOpeningTime] = useState<string | undefined>('');
    const [closingTime, setClosingTime] = useState<string | undefined>('');
    const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const currentMoment = TimeHelper.now();
    const formattedDate = currentMoment.format("D MMMM dddd YYYY");

    const getBackgroundStyle = () => {
        switch (activeTab) {
            case 'GünlükPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '120vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    borderBottomLeftRadius:'6px'
                };
            case 'HaftalıkPlan':
                return {
                    backgroundImage: `url(${leftColumnBackground})`,
                    height: '200vh',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                };
            default:
                return {};
        }
    };

    const handleBecomeOnline = async () => {
        if (!checkboxChecked) {
            toast.error('Lütfen boş alanları doldurun.')
            return;
        }

        if (selectedDiscountValue === null || selectedDiscountValue < 0) {
            toast.error("Lütfen indirim oranı seçiniz")
            return;
        }

        setErrorMessage(null);

        const today = moment().format('YYYY-MM-DD');

        const formattedStartDateTime = `${today}T${openingTime}`;
        const formattedFinishDateTime = `${today}T${closingTime}`;
        let startDateTime = moment(formattedStartDateTime).format('YYYY-MM-DDTHH:mm:ss');
        let finishDateTime = moment(formattedFinishDateTime).format('YYYY-MM-DDTHH:mm:ss');
    
        if (finishDateTime <= startDateTime) {
            const finishDateTimeAsDate = new Date(finishDateTime); // String'i Date nesnesine dönüştür
            finishDateTimeAsDate.setTime(finishDateTimeAsDate.getTime() + 24 * 60 * 60 * 1000); // 24 saat ekle
            finishDateTime = moment(finishDateTimeAsDate).format('YYYY-MM-DDTHH:mm:ss'); // Formatlanmış string'e çevir
        }
        

        const campaignData = {
            automationId: automationId,
            companyId: Repository.getMyCompany()?.id ?? '',
            promotionType: 0,
            startDate: startDateTime,
            finishDate: finishDateTime,
            discountRate: selectedDiscountValue || 0,
        };

        try {
            const result = await Repository.createCampaign(campaignData);
            if (result) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDiscountValueClick = (value: number) => {
        setSelectedDiscountValue(value);
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckboxChecked(e.target.checked);
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-2">
                    <div className='backgroundDiv'>
                        <img style={getBackgroundStyle()} src={leftColumnBackground} alt="background" />
                    </div>
                </div>
                <div className="col-sm-10">
                    <div style={{ padding: '40px' }}>
                        <div className='titleLiveRightNov'>
                            <div className=' titleOnline stopSession'>
                                <h2 style={{ marginTop: '20px', marginLeft: '-20px' }}>
                                    İndirim Aç
                                </h2>
                                <button id='close-button' onClick={onClose} style={{ marginTop: '20px' }}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                            </div>
                            <p style={{ marginLeft: '-20px' }}>Bugün {formattedDate}</p>

                            <hr className='line' style={{ width: '100%', marginLeft: '-20px', marginTop: '40px', marginBottom: '40px' }} />
                        </div>
                        <div className='row weekly-plan-content' style={{ height: 'unset' }}>
                            <div className='edit-campaign-times-container' style={{ display: 'flex' }}>


                                <div style={{ marginRight: '60px' }}>
                                    <p className='weekly-active-plan-title'>Ne zaman açılsın?</p>
                                    <input
                                    className='missing-day-first-time-input'
                                        placeholder={`${openingTime}`}
                                        type="time"
                                        value={openingTime}
                                        onChange={(e) => setOpeningTime(e.target.value)}
                                        style={{
                                            color: "black",
                                            backgroundColor: "white",
                                            border: '1px dashed #1D1E2533',
                                            borderRadius: '12px',
                                            padding: '16px',
                                            height: '82px',
                                            width: '160px',
                                            appearance: 'textfield',
                                            WebkitAppearance: 'none',
                                            MozAppearance: 'textfield',
                                        }}
                                    />

                                </div>

                                <div className='weekly-middle-div'
                                    style={{
                                        height: '120px',
                                        width: '1px',
                                        backgroundColor: '#1D1E2533',
                                        marginRight: '60px'
                                    }}
                                ></div>

                                <div>
                                    <p className='weekly-active-plan-title'>
                                        Ne zaman kapansın?
                                    </p>
                                    <input
                                        placeholder={`${closingTime}`}
                                        type="time"
                                        value={closingTime}
                                        onChange={(e) => setClosingTime(e.target.value)}
                                        style={{
                                            color: "black",
                                            backgroundColor: "white",
                                            border: '1px dashed #1D1E2533',
                                            borderRadius: '12px',
                                            padding: '16px',
                                            height: '82px',
                                            width: '160px',
                                            appearance: 'textfield',
                                            WebkitAppearance: 'none',
                                            MozAppearance: 'textfield',
                                        }}
                                    />

                                </div>

                            </div>

                            <hr className='line' style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />

                            <div>
                                <p className='weekly-active-plan-title'>Ne kadarlık bir indirim açılsın?</p>
                                <div className='discountButtons'>
                                    {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
                                        <button style={{ marginBottom: '25px' }}
                                            key={value}
                                            onClick={() => handleDiscountValueClick(value)}
                                            className={selectedDiscountValue === value ? 'selected-button' : 'normal-button'}>
                                            {`${value}%`} {selectedDiscountValue === value && '🤩'}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className='contract' style={{ padding: '24px' }}>
                                <Checkbox onChange={handleCheckboxChange} checked={checkboxChecked} sx={{
                                    '&.Mui-checked': {
                                        color: '#F15F50',
                                    },
                                    '&.Mui-checked:hover': {
                                        backgroundColor: 'rgba(255, 165, 0, 0.08)',
                                    }
                                }} style={{ transform: 'scale(1.5)', marginRight: '10px' }} ></Checkbox>
                                <p>Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz olarak sunacağımı kabul ederim.</p>
                            </div>
                            <div id='companyBecomeOnlineButton'>
                                <button onClick={handleBecomeOnline} style={{ marginTop: '20px', backgroundColor: '#F15F50', fontSize: '16px', paddingTop: '16px', paddingLeft: '22px', paddingBottom: '16px', paddingRight: '22px',color:'#FFFF',border:'none' }}>
                                    <img src={arrow} alt="arrow" /> İndirim Aç
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
