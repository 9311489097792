import React from 'react'

export default function FlashOfferComingSoonScreen() {
    const bg = require('../images/weeklyplan.png')
    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-sm-2 backgroundDiv'>
            <img style={{ height: '100%' }} src={bg} />
  
          </div>
          <div className='col-sm-10 contentPart'>
            <div className='comingSoon'>
              <h1>⌛️</h1>
              <h4 className='comingSoonTitle'>Çok Yakında</h4>
              <p>Müşterilerinize çok yakında Flash Teklif’ler sunabileceksiniz. Flash Teklif’ler aktif olduğunda sizlere mail ve bildirim aracılığı ile haber verilecektir.</p>
              <p>Teşekkürler.</p>
              <p className='comingSoonQuestion'>Ne Zaman Flash Teklifleri Açabileceğim?</p>
              <p>2024</p>
            </div>
          </div>
        </div>
      </div>
    )
}
