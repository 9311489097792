import React, { useEffect, useState } from 'react'
import '../styles/style.css'
import { AiOutlineClose } from 'react-icons/ai';
import { Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CampaignDTO } from '@/model/dto/CampaignDTO';
import moment, { now } from 'moment';
import baseUrl from '../../api/baseUrl';
import { cdnBaseUrl } from '../../api/baseUrl';
import { Repository } from '../../data/repository/repository';
import { DocumentDTO } from '@/model/dto/DocumentDTO';


interface Props {
    onClose: () => void;
    automation: boolean | null
}

export default function StopCampaignSessionPopup({ onClose, automation }: Props) {
    const warning = require('../images/icons/Warning--hex.png');
    const arrow = require('../images/icons/Text--new-line.png')
    const [campaignId, setCampaignId] = useState('')
    const navigate = useNavigate();
    const [activeQrCount, setActiveQrCount] = useState<number | null>(0);

    const [aggrementCheckbox, setAggrementCheckbox] = useState<boolean>(false);

    const [documents, setDocuments] = useState<DocumentDTO[]>([]);
    const [documentUserAgreementUrl, setDocumentUserAgreementUrl] = useState<string>('');
    const [documentPrivacyUrl, setDocumentPrivacyUrl] = useState<string>('');
    const [documentTermsAndConditionsUrl, setDocumentTermsAndConditionsUrl] = useState<string>('');


    useEffect(() => {
        const getDocuments = async () => {
            var result = await Repository.getAppDocuments()
            setDocuments(result ?? [])
        };

        getDocuments();

    }, []);

    useEffect(() => {
        if (documents !== null && documents.length > 0) {
            setDocumentTermsAndConditionsUrl(getDocumentUrlByCode('A1'));
            setDocumentUserAgreementUrl(getDocumentUrlByCode('A2'));
            setDocumentPrivacyUrl(getDocumentUrlByCode('P101P'));
        }
    }, [documents]);



    const getDocumentUrlByCode = (code: string) => {
        try {
            var document = documents.filter(d => d.code === code)[0];

            return cdnBaseUrl + document.documentUrl;
        }
        catch (error) {
            return "";
        }
    }

    useEffect(() => {
        const fetchCampaignsData = async () => {
            try {
                const campaigns = await Repository.getCampaignsByCompanyId(Repository.getMyCompany()?.id ?? '') ?? [];
                const activeObjectIds: string[] = [];
                const now = new Date();


                campaigns.forEach((obj: CampaignDTO) => {

                    if (obj.promotionType === 1) {
                        return;
                    }
                    const startDate = moment(obj.startDate, "DD/MM/YYYY HH:mm:ss").toDate();
                    const finishDate = moment(obj.finishDate, "DD/MM/YYYY HH:mm:ss").toDate();
                    if (automation === true) {
                        if (finishDate > now) {
                            activeObjectIds.push(obj.id);
                        }
                    } else {
                        if (finishDate > now && (obj.isActive || startDate > now)) {
                            activeObjectIds.push(obj.id);
                        }
                    }

                });

                if (activeObjectIds.length > 0) {
                    const firstCampaignId = activeObjectIds[0];
                    setCampaignId(firstCampaignId);

                    const qrCodes = await Repository.getQrCodeByCampaignId(firstCampaignId, Repository.getMyCompany()?.id ?? '', '0');

                    const activeQrCodes = qrCodes?.filter((qr: { status: number }) => qr.status === 0) ?? [];
                    setActiveQrCount(activeQrCodes.length);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchCampaignsData();
    }, []);


    const handleAggrementCheckboxValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAggrementCheckbox(event.target.checked);
    };

    const handleStopSession = async () => {
        try {
            if (aggrementCheckbox === false) {
                toast.error('Lütfen kullanım koşullarını onaylayınız!');
                return;
            }

            const success = await Repository.PutCampaignsFinish(campaignId);
            if (success) {
                toast.success('Kampanya Durduruldu!');
                onClose();
                window.location.reload()
            } else {
                toast.error('Kampanya Durdurma Başarısız!');
            }
        } catch (error) {
            toast.error('Bir Hata Meydana Geldi!');
        }
    };


    return (
        <div className='custom-overlay' >
            <div className="popup-content" style={{ height: 'unset' ,backgroundColor:'#F9FBFC'}}>
                <div className='stopSession'>
                    <img src={warning} style={{ width: '100px' }} />
                    <button id='close-button' onClick={onClose}><AiOutlineClose style={{ color: '#F15F50', marginRight: '5px' }} />Vazgeç</button>
                </div>
                <div className='content'>
                    <h5>Oturumunuzu Durdurmak Üzeresiniz!</h5>
                    <p className='stopSessionFirstP'>Şu anda aktif bir oturumu kapatmak üzere olduğunuzu hatırlatırız.</p>
                    <p className='stopSessionSecondP'>Oturumu kapattıktan sonra, restoranınıza yola çıkmış kullanıcılar, sizden aldıkları QR kodlarını kullanarak indirimlerini talep etmeye devam edebilirler.</p>
                    <p className='qrCount'>Aktif QR Sayısı: {activeQrCount}</p>
                </div>
                {/* <hr style={{ width: '100%', marginBottom: '50px' }} /> */}
                <div>
                    {/* <h5 className='rUSure'>Oturumu kapatmak istediğinizden emin misiniz?</h5> */}
                </div>


                <div className='contract' style={{backgroundColor:'#FFFF'}}>
                    <Checkbox sx={{
                        '&.Mui-checked': {
                            color: '#F15F50'
                        },
                        '&.Mui-checked:hover': {
                            backgroundColor: 'rgba(255, 165, 0, 0.08)',
                        }
                    }}  style={{ transform: 'scale(1.5)', marginRight: '10px' }} onChange={handleAggrementCheckboxValueChanged}></Checkbox>
                    <p>Yola çıkmış kullanıcıların indirim veya flash teklif taleplerini kullanmaya devam edeceğini anlıyor ve bu durumu kabul ediyorum.</p>
                </div>
                <div className='confirmButtons'>
                    <button id='confirmButtonYes' onClick={handleStopSession} className='confirmButtonYes'><img src={arrow} /> Evet</button>
                    <button id='confirmButtonNo' onClick={onClose} className='confirmButtonNo'>Hayır</button>

                </div>
            </div>
        </div>
    )
}
