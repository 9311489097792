import React, { useEffect, useState } from 'react';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';
import { Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { Form, Modal } from 'react-bootstrap';
import { cdnBaseUrl } from '../../api/baseUrl';
import NotificationsDetailPopup from '../popup/NotificationsDetailPopup';
import { CityDTO } from '../../model/dto/CityDTO';
import { CampaignDTO } from '../../model/dto/CampaignDTO';
import MyEditor from './MyEditor';
import { Repository } from '../../data/repository/repository';
import { NotificationDTO } from '@/model/dto/NotificationDTO';
import { ImageDTO } from '@/model/dto/ImageDTO';
import { TimeHelper } from '../../helpers/TimeHelper';



export default function NotificationScreen() {
    const [notifications, setNotifications] = useState<NotificationDTO[] | null>([]);
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
    const [cities, setCities] = useState<CityDTO[]>([]);
    const [campaigns, setCampaigns] = useState<CampaignDTO[]>([]);
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [selectedCampaign, setSelectedCampaign] = useState<string>('');
    const [cityName, setCityName] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<string>('');
    const bg = require('../images/icons/Sol_color.png');
    const [file, setFile] = useState<File | null>(null);
    const [uploadedImgUrl, setUploadedImgUrl] = useState<ImageDTO | string | null>()
    const [inAppContent, setInAppContent] = useState<string>('');
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [selectedNotification, setSelectedNotification] = useState<NotificationDTO| null>(null);
    const [isSending, setIsSending] = useState(false);
    const [showEditor, setShowEditor] = useState<boolean>(false);

    const upload = require('../images/icons/Cloud--upload.png')

    const handleCreateNotification = async () => {
        try {

            if (title === '') {
                toast.error("Lütfen bildirim başlığını giriniz");
                return;
            }

            if (content === '') {
                toast.error("Lütfen bildirim içeriğini giriniz");
                return;
            }


            let inAppContentResult = null;

            if (selectedOption === 'İçerik Oluştur') {
                inAppContentResult = await Repository.postInAppContent(inAppContent);
                
                if (!inAppContentResult) {
                    toast.error("İçerik oluşturulurken bir hata oluştu");
                    return;
                }
            }

            const notificationData: NotificationDTO = {
                title,
                content,
                imageUrl: uploadedImgUrl ? cdnBaseUrl + uploadedImgUrl : null,
                creationDate: TimeHelper.now().format('YYYY-MM-DDTHH:mm:ss'),
                campaignId: selectedCampaign ? selectedCampaign : null,
                inAppContentId: inAppContentResult ? inAppContentResult : null
            };



            await Repository.sendNotification(notificationData);
            setIsSending(true);

            const newNotifications = await Repository.fetchNotifications() ?? [] ;
            setNotifications(newNotifications);

            setContent('');
            setTitle('');
            setImageUrl('');
            setUploadedImgUrl(null)
            setSelectedCity('')
            setSelectedOption('');
            setShowCreateForm(false);
            setSelectedCampaign('');
            setInAppContent('')
            setShowEditor(false)
            setIsSending(false)
        } catch (error) {
            console.error('Bildirim oluşturulurken bir hata oluştu:', error);
        }
    };
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];
        if (selectedFile) {
            setFile(selectedFile);
            try {
                const uploadedImageUrl = await Repository.uploadFile(selectedFile);
                setUploadedImgUrl(uploadedImageUrl?.url);
            } catch (error) {
                console.error('Resim yükleme hatası:', error);
            }
        }
    };

    const handleCityChange = async (event: SelectChangeEvent<string>) => {


        setSelectedCity(event.target.value);

        setSelectedCampaign('');
        try {
            var data = await Repository.getCampaignsByCityId(event.target.value);

            if (data !== null) {
                if (data !== null) {
                    setCampaigns(data);
                }
            }
        }
        catch (error) {
        }
    };

    const handleRedirectionChange = async (event: SelectChangeEvent<string>) => {


        setSelectedOption(event.target.value as string);

        setSelectedCampaign('');
        setSelectedCity('');
        setShowEditor(false);

    };

    const handleClick = () => {
        const inputElement = document.getElementById('hiddenInput') as HTMLInputElement | null;
        if (inputElement) {
            inputElement.click();
        }
    }

    const handleDeleteImg = () => {
        setUploadedImgUrl(null)
    }

    const fetchCities = async () => {
        try {
            const citiesData = await Repository.getCities();
            if (citiesData) {


                setCities(citiesData);

            } else {
                console.error('API\'den şehir bilgileri alınamadı.');
            }
        } catch (error) {
            console.error('API\'ye bağlanırken bir hata oluştu:', error);
        }
    };

    const handleNotificationClick = (notification: NotificationDTO) => {
        setSelectedNotification(notification);
        setShowPopup(true);
    }

    const closePopup = () => {
        setShowPopup(false);
        setSelectedNotification(null);
    }



    useEffect(() => {
        fetchCities()
    }, [])

    useEffect(() => {
        const getNotifications = async () => {
            try {
                const notificationData = await Repository.fetchNotifications();
                setNotifications(notificationData);
            } catch (error) {
                console.error('Bildirimleri alırken bir hata oluştu:', error);
            }
        };

        getNotifications();
    }, []);

    const toggleCreateForm = () => {
        setShowCreateForm(!showCreateForm);
    };




    return (
        <div style={{ overflowY: 'hidden',marginLeft:'-15px' }} className='container-fluid'>
            <div style={{ overflowY: 'hidden' }} className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-2 backgroundDiv'>
                        <img src={bg} />
                    </div>

                    <div className='col-sm-10 content-admin'>
                        <div className='notification-title'>
                            <h1>Bildirimler</h1>
                            {showCreateForm ? (
                                <button className='new-notification' onClick={toggleCreateForm}>
                                    <><FaTimes style={{ marginRight: '5px', color: 'red' }} /> Vazgeç</>
                                </button>
                            ) : (
                                <button className='create-notification' onClick={toggleCreateForm}>
                                    <><FaPaperPlane style={{ marginRight: '5px' }} /> Yeni Oluştur</>
                                </button>
                            )}
                        </div>
                        <hr style={{ width: '100%' }} />

                        {showCreateForm && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <TextField
                                        label="Bildirim Başlığı"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Bildirim İçeriği"
                                        value={content}
                                        onChange={(e) => setContent(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                {uploadedImgUrl ? (
                                    <div style={{ justifyContent: 'flex-start', marginLeft: '15px', width: '100%' }} className='upload-image-flash-offer'>
                                        <div>
                                            <div>
                                                <img className='uploadImageIcon' style={{ width: '80px', height: '80px', padding: '0px', marginRight: '20px', borderRadius: '50px' }} src={`${cdnBaseUrl}${uploadedImgUrl}`} />

                                            </div>

                                        </div>
                                        <Form.Group controlId="inputArea">
                                            <input

                                                id="hiddenInput"
                                                className='flash-offer-upload-img-input'
                                                style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                                                type="file"
                                                accept=".jpg,.jpeg,.png"
                                                onChange={handleFileChange}


                                            />
                                        </Form.Group>
                                        <div style={{ display: 'block' }}>
                                            <label style={{ fontSize: '16px', textAlign: 'left' }}>Bildirim Gōrseli ✅ </label><br />
                                            <button className='delete-flash-offer-img' onClick={handleDeleteImg}>Sil</button>

                                        </div>
                                    </div>
                                ) : (
                                    <div style={{ justifyContent: 'flex-start', marginLeft: '15px', width: '100%' }} className='upload-image-flash-offer'>
                                        <div>
                                            <img className='flash-offer-upload-image-icon' src={upload}
                                                onClick={handleClick}
                                            />

                                        </div>
                                        <Form.Group controlId="inputArea">
                                            <input

                                                id="hiddenInput"
                                                className='flash-offer-upload-img-input'
                                                style={{ marginTop: '-13px', marginLeft: '-88px', display: 'none' }}
                                                type="file"
                                                accept=".jpg,.jpeg,.png"
                                                onChange={handleFileChange}


                                            />
                                        </Form.Group>
                                        <label>Bildirim için görsel yükleyin.</label>
                                    </div>
                                )}


                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-label">Yönlendirme</InputLabel>
                                        <Select
                                            labelId="select-label"
                                            label='Yönlendirme'
                                            value={selectedOption}
                                            onChange={handleRedirectionChange}
                                        >
                                            <MenuItem value="">Yok</MenuItem>
                                            <MenuItem value="Kampanya">Kampanya</MenuItem>
                                            <MenuItem value="İçerik Oluştur">İçerik Oluştur</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {selectedOption === 'Kampanya' && (
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="city-label">Kampanya Şehir</InputLabel>
                                            <Select
                                                labelId="city-label"
                                                label='Kampanya Şehir'
                                                value={selectedCity}
                                                onChange={handleCityChange}
                                            >
                                                {cities.map((city, index) => (
                                                    <MenuItem key={index} value={city.name}>
                                                        {city.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth style={{ marginTop: '16px' }}>
                                            <InputLabel id="campaign-label">Kampanya</InputLabel>
                                            <Select
                                                labelId="campaign-label"
                                                label='Kampanya'
                                                value={selectedCampaign}
                                                onChange={(e) => setSelectedCampaign(e.target.value)}
                                            >
                                                {campaigns.map((campaign, index) => (
                                                    <MenuItem key={index} value={campaign.id}>
                                                        {campaign.companyMeta.name + '-' + (campaign.promotionType == 0 ? 'Kampanya' : campaign.promotionType == 1 ? 'Flash Deal' : '')}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedOption === 'İçerik Oluştur' && (
                                    <Grid item xs={12}>
                                        <Button style={{border:'1px solid #F15F50 ',color:'#F15F50'}}  onClick={() => setShowEditor(true)}>Harİcİ İçerİğİ Oluştur</Button>
                                    </Grid>
                                )}
                                {showEditor && (
                                    <Grid item xs={12}>
                                        <MyEditor onSaveContent={(inAppContent: string) => setInAppContent(inAppContent)} />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Button disabled={isSending === true} className='create-notification-button' variant="contained" onClick={handleCreateNotification}>Bildirim Gönder</Button>
                                </Grid>
                            </Grid>
                        )}

                        {!showCreateForm && (
                            <ul className='notifications'>
                                {notifications && notifications?.length > 0 ? (
                                    notifications?.map((notification, index) => (
                                        <li key={index} onClick={() => handleNotificationClick(notification)}>
                                            <h5 className='notifications-title'>{notification.title}</h5>
                                            <label className='notifications-creation-date'>{notification.creationDate}</label>
                                            <hr style={{ width: '95%' }} />
                                        </li>

                                    ))

                                ) : (
                                    <div className='no-notification'>
                                        <h1>😉</h1>
                                        <br />
                                        <h4>Bildirim Yok</h4>
                                        <p>Şu anda görüntülenecek bir bildirim yok.</p>
                                    </div>
                                )}
                            </ul>
                        )}


                        {showPopup === true && selectedNotification && (
                            <Modal
                                show={showPopup}
                                onHide={closePopup}
                                overlayClassName="custom-overlay"
                            >
                                <NotificationsDetailPopup onClose={closePopup} notificationInfo={selectedNotification} />
                            </Modal>


                        )}

                    </div>
                </div>




            </div>
        </div>
    );
}
