import { TimeHelper } from '../../helpers/TimeHelper';
import React, { useEffect, useState } from 'react'
import '../styles/ActiveWeeklyStyle.css';
import { Repository } from '../../data/repository/repository';
import { AutomationDTO } from '../../model/dto/AutomationDTO';
import { Modal } from 'react-bootstrap';
import CancelAutomation from '../popup/CancelAutomation';
import OpenMissingDayPopup from '../popup/OpenMissingDayPopup';

export default function MissingDayDiscountOpeningScreen() {
    const bg = require('../images/icons/Sol_color-2.png');
    const stop = require('../images/icons/stop.png')
    const arrow = require('../images/icons/Text--new-line.png');
    const weekDays = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"];

    const currentMoment = TimeHelper.now();
    const formattedDate = currentMoment.format("D MMMM dddd YYYY");

    const [automationInfo, setAutomationInfo] = useState<AutomationDTO | null>()
    const [openCancelAutomationModal, setOpenCancelAutomationModal] = useState<boolean>(false)
    const [openNewCampaign, setOpenNewCampaign] = useState<boolean>(false)


    const getEarliestDay = () => {
        if (!automationInfo?.activeDays || automationInfo.activeDays.length === 0 || !automationInfo.startDate) return null;
    
        const today = currentMoment.toDate(); 
        const todayDayOfWeek = today.getDay();
        const currentTime = currentMoment.format("HH:mm");
        if (
            automationInfo.activeDays.includes(todayDayOfWeek) && 
            automationInfo.startTime && 
            automationInfo.finishTime && 
            currentTime < automationInfo.finishTime
        ) {
            return `${today.getDate()} ${today.toLocaleString('tr-TR', { month: 'long' })} ${weekDays[todayDayOfWeek]} saat ${automationInfo.startTime.substring(0, 5)}`;
        }
    
        for (let i = 1; i <= 7; i++) {
            const checkDate = new Date(today);
            checkDate.setDate(today.getDate() + i); 
            const dayOfWeek = checkDate.getDay(); 
    
            if (automationInfo.activeDays.includes(dayOfWeek)) {
                return `${checkDate.getDate()} ${checkDate.toLocaleString('tr-TR', { month: 'long' })} ${weekDays[dayOfWeek]} saat ${automationInfo.startTime.substring(0, 5)}`;
            }
        }
        return null;
    };
    



    const fetchCampaignsData = async () => {
        try {
            const hasActiveAutomation = await Repository.getActiveAutomation(Repository.getMyCompany()?.id ?? '');
            setAutomationInfo(hasActiveAutomation)

        } catch (error) {
            console.error(error);
        };
    }

    const handleOpenCancelAutomationModal = () => {
        setOpenCancelAutomationModal(true)
    }
    const handleCloseCancelAutomation = () => {
        setOpenCancelAutomationModal(false)
    }

    const handleOpenMissingDayPopup = () => {
        setOpenNewCampaign(true)
    }
    const handleCloseMissingDayPopup = () => {
        setOpenNewCampaign(false)
    }

    useEffect(() => {
        fetchCampaignsData()
    }, [])

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-2">
                        <div className="backgroundDiv">
                            <img src={bg} alt="background" style={{ height: '80vh' }} />
                        </div>
                    </div>
                    <div className="col-sm-10">
                        <div style={{ padding: '40px', height: ' calc(100vh - 280px)' }}>
                            <div className="titleLiveRightNov">
                                <div className="liveRightNov" style={{ marginLeft: '-20px' }}>
                                    <h2 style={{ fontWeight: '600', fontSize: '24px', lineHeight: '34px', paddingLeft: '20px', color: '#F15F50' }}>İndiriminiz açık değil...</h2>
                                    <h6 style={{ fontWeight: '700', color: '#1D1E25', fontSize: '20px', lineHeight: '30px' }}>Bugün {formattedDate}</h6>
                                </div>
                                <hr style={{ width: '100%', marginBottom: '16px', marginTop: '24px' }} />
                            </div>

                            <div className="row upperContent" style={{ display: 'flex', justifyContent: 'center', maxWidth: 'unset' }}>
                                {automationInfo?.activeDays && automationInfo.activeDays.length > 0 ? (
                                    <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '20px', marginLeft: '20px' }}>
                                        Otomatik olarak kurduğunuz haftalık planınız {automationInfo?.activeDays.map(day => weekDays[day]).join(", ")} günleri saat {automationInfo?.startTime?.substring(0, 5)} ila {automationInfo?.finishTime?.substring(0, 5)} arasında %{automationInfo?.discountRate} indirim olarak ayarlandı.
                                    </p>
                                ) : (
                                    <p style={{ fontWeight: '400', fontSize: '16px', lineHeight: '20px', marginLeft: '20px' }}>
                                        Otomasyon planınızdaki indirim günleri başarı ile tamamlandı.
                                    </p>
                                )}
                            </div>



                            <hr style={{ width: '100%', marginBottom: '16px', marginTop: '16px' }} />
                            <div>
                                {automationInfo?.activeDays && automationInfo.activeDays.length > 0 && (
                                    <>
                                        <p style={{ marginBottom: '4px' }}>Ne zaman otomatik açılacak?</p>
                                        <p style={{ fontWeight: '700', fontSize: '18px', lineHeight: '28px' }}>
                                            {getEarliestDay()}
                                        </p>
                                        <hr style={{ width: '100%', marginBottom: '40px', marginTop: '40px' }} />

                                    </>
                                )}
                            </div>

                            <div className="weekly-plan-buttons">
                                <h4 style={{ fontWeight: '400', fontSize: '24px', lineHeight: '34px', marginBottom: '16px' }}>Bugün indirim açmak ister misin?</h4>
                                <button onClick={handleOpenMissingDayPopup} className='weekly-orange-button'> <img style={{ width: '20px' }} src={arrow} /> İndirimi Aç</button>
                                <button onClick={handleOpenCancelAutomationModal} className='weekly-light-button'><img style={{ width: '20px' }} src={stop} /> Haftalık Planı İptal Et</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Modal size='xl'
                    show={openCancelAutomationModal}
                    onHide={handleCloseCancelAutomation}
                    style={{ maxHeight: '95vh' }}
                >
                    <CancelAutomation campaignId={automationInfo?.id} onClose={handleCloseCancelAutomation} />
                </Modal>
            </div>
            <div>
                <Modal size='xl'
                    show={openNewCampaign}
                    onHide={handleCloseMissingDayPopup}
                    style={{ maxHeight: '95vh' }}
                >
                    <OpenMissingDayPopup startTime={automationInfo?.startTime ?? ''} finishTime={automationInfo?.finishTime ?? ''} startDate={automationInfo?.startDate ?? ''} finishDate={automationInfo?.finishDate ?? ''} automationId={automationInfo?.id ?? ''} onClose={handleCloseMissingDayPopup} />
                </Modal>
            </div>
        </div>
    )
}
