import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeHelper } from '../../helpers/TimeHelper';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Repository } from '../../data/repository/repository';
import { toast } from 'react-toastify';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';

const daysOfWeek = [
  { label: 'Pazartesi', value: 1 },
  { label: 'Salı', value: 2 },
  { label: 'Çarşamba', value: 3 },
  { label: 'Perşembe', value: 4 },
  { label: 'Cuma', value: 5 },
  { label: 'Cumartesi', value: 6 },
  { label: 'Pazar', value: 0 },
];

export default function CreateWeeklyPlanningScreen() {
  const arrow = require('../images/icons/Text--new-line.png');

  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [openingTime, setOpeningTime] = useState<string | undefined>('');
  const [closingTime, setClosingTime] = useState<string | undefined>('');
  const [selectedDiscountValue, setSelectedDiscountValue] = useState<number | null>(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [recurringCheckboxChecked, setRecurringCheckboxChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedStartTime, setselectedStartTime] = useState<moment.Moment | null>(null);
  const [selectedFinishTime, setSelectedFinishTime] = useState<moment.Moment | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedDays((prev) =>
      prev.includes(value) ? prev.filter((day) => day !== value) : [...prev, value]
    );
  };

  const isFormValid =
    openingTime !== '' &&
    closingTime !== '' &&
    selectedDays.length > 0 &&
    selectedDiscountValue !== null &&
    checkboxChecked;

  const handleDiscountValueClick = (value: number) => {
    setSelectedDiscountValue(value);
  };


  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleRecurringCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecurringCheckboxChecked(e.target.checked);
  };


  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const parseTimeStringToDate = (time: string | undefined): Date | null => {
    if (!time) return null;

    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  };


  const handleSubmit = async () => {
    if (!checkboxChecked) {
      setErrorMessage('Lütfen boş alanları doldurun.')
      return;
    }
    if (!isFormValid) {
      setErrorMessage('Lütfen tüm alanları doldurun.');
      return;
    }
    if (selectedDiscountValue === null || selectedDiscountValue < 0) {
      toast.error("Lütfen indirim oranı seçiniz")
      return;
    }

    if (selectedStartTime !== null && selectedStartTime >= selectedFinishTime!) {
      toast.error("Başlama tarihi bitiş tarihinden büyük veya eşit olamaz")
      return;
    }

    setErrorMessage(null);

    const today = new Date();
    const startDate = formatDate(today);

    const oneWeekFromToday = new Date();
    oneWeekFromToday.setDate(today.getDate() + 7);
    const finishDate = formatDate(oneWeekFromToday);


    const data = {
      companyId: Repository.getMyCompany()?.id ?? '',
      activeDays: selectedDays.map(String),
      startTime: TimeHelper.formatTimeForAutomation(parseTimeStringToDate(openingTime)) || '',
      finishTime: TimeHelper.formatTimeForAutomation(parseTimeStringToDate(closingTime)) || '',
      startDate,
      finishDate,
      discountRate: selectedDiscountValue || 0,
      recurring: recurringCheckboxChecked ? true : false 
    };


    try {
      const response = await Repository.postAutomation(data);

      if (response) {
        console.log('İşlem başarılı:', response);
        window.location.reload();
      } else {
        setErrorMessage('Plan sırasında bir hata oluştu.');
      }
    } catch (error) {
      console.error('Plan sırasında bir hata oluştu:', error);
      setErrorMessage('Plan sırasında bir hata oluştu.');
    }
  };
  return (
    <div>
      <h2 style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Hangi günler indirim açılsın?</h2>
      <FormGroup className='weekly-planning-days' style={{ padding: '10px' }} row>
        {daysOfWeek.map((day) => (
          <FormControlLabel
            key={day.value}
            className={selectedDays.includes(day.value.toString()) ? 'active-checkbox' : 'custom-checkbox-label'}
            control={
              <Checkbox
                checked={selectedDays.includes(day.value.toString())}
                onChange={handleChange}
                value={day.value}
                sx={{
                  '&.Mui-checked': {
                    color: '#F15F50',
                    '& .MuiSvgIcon-root': {
                      border: '1.5px solid #1D1E25',
                      borderRadius: '4px'
                    },
                  },
                  '&.Mui-checked:hover': {
                    backgroundColor: 'rgba(255, 165, 0, 0.08)',
                  }
                }}
              />
            }
            label={day.label}
          />
        ))}
      </FormGroup>
      <hr className='line' style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />
      <div className='weekly-planning-time-selection' style={{ display: 'flex', alignItems: 'center' }}>
        <div className='weekly-planning-first-time-selection'>
          <h2 className='weekly-planning-subtitle' style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Ne zaman açılsın?</h2>
          <input
            placeholder={`${openingTime}`}
            type="time"
            value={openingTime}
            onChange={(e) => setOpeningTime(e.target.value)}
            style={{
              color: "black",
              backgroundColor: "white",
              border: '2px dashed #1D1E25',
              borderRadius: '12px',
              padding: '16px',
              height: '82px',
              width: '160px',
              appearance: 'textfield',
              WebkitAppearance: 'none',
              MozAppearance: 'textfield',
              fontSize: '25px',
              fontWeight: '600'
            }}
          />
        </div>
        <div
          className='weekly-middle-div'
          style={{
            height: '120px',
            width: '1px',
            backgroundColor: '#1D1E2533',
            marginRight: '60px',
            marginLeft: '60px'
          }}
        ></div>
        <div>
          <h2 className='weekly-planning-subtitle' style={{ fontSize: '20px', fontWeight: '200', marginBottom: '12px' }}>Ne zaman kapansın?</h2>
          <input
            placeholder={`${closingTime}`}
            type="time"
            value={closingTime}
            onChange={(e) => setClosingTime(e.target.value)}
            style={{
              color: "black",
              backgroundColor: "white",
              border: '2px dashed #1D1E25',
              borderRadius: '12px',
              padding: '16px',
              height: '82px',
              width: '160px',
              appearance: 'textfield',
              WebkitAppearance: 'none',
              MozAppearance: 'textfield',
              fontSize: '25px',
              fontWeight: '600'
            }}
          />
        </div>

      </div>

      <hr className='line' style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }} />

      <div>
        <p className='weekly-planning-subtitle'>Ne kadarlık bir indirim açılsın?</p>
        <div className='weekly-planning-discount-buttons'>
          {Array.from({ length: 9 }, (_, i) => i * 5 + 10).map((value) => (
            <button
              key={value}
              onClick={() => handleDiscountValueClick(value)}
              className={`discount-button 
                ${value === 50 && !selectedDiscountValue ? 'special-discount-button' : ''} 
                ${selectedDiscountValue === value ? 'selected-discount-button' : ''}`}>
              {`${value}%`} {selectedDiscountValue === value && '🤩'}
            </button>
          ))}


        </div>
      </div>
      <hr className='line-mobile-display' style={{ width: '100%', marginBottom: '40px', marginTop: '40px' }} />
      <div className='contract' style={{border:'1px solid #F15F50'}}>
        <Checkbox sx={{
          '&.Mui-checked': {
            color: '#F15F50'
          },
          '&.Mui-checked:hover': {
            backgroundColor: 'rgba(255, 165, 0, 0.08)',
          }
        }} style={{ transform: 'scale(1.5)', marginRight: '10px' }} onChange={handleRecurringCheckboxChange} checked={recurringCheckboxChecked} />

        <p style={{fontWeight:'600'}}>Bu haftalık plan her hafta otomatik olarak tekrar etsin.</p>
      </div>

      <div className='contract'>
        <Checkbox sx={{
          '&.Mui-checked': {
            color: '#F15F50'
          },
          '&.Mui-checked:hover': {
            backgroundColor: 'rgba(255, 165, 0, 0.08)',
          }
        }} style={{ transform: 'scale(1.5)', marginRight: '10px' }} onChange={handleCheckboxChange} checked={checkboxChecked} />

        <p>Belirttiğim indirimi, iş birliği sözleşmesi doğrultusunda tüm kullanıcılara eksiksiz olarak sunacağımı kabul ederim.</p>
      </div>
      {errorMessage !== null ? <p style={{ color: 'red' }}>{errorMessage}</p> : ''}

      <div className='companyBecomeOnlineButton'>
        <button disabled={!isFormValid} style={{ marginBottom: '40px' }} onClick={handleSubmit}><img style={{ marginRight: '5px' }} src={arrow} alt="arrow" />Online Ol
        </button>
      </div>

    </div>
  );
}
